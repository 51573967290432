import '../common/js/font';

import '../common/css/main.css'; // 公共样式
import '../common/js/common';
import '../common/css/header.less'; // 头部样式
import '../common/js/header';
import '../common/css/footer.less'; // 底部样式

// swiper
import '../static/swiper/swiper.min.css';
import '../static/animate/animate.min.css';
