(function (doc, win) {
  var docEl = doc.documentElement,
    resizeEvt = 'onorientationchange' in window ? 'orientationchange' : 'resize',
    remCalc = function () {
      var clientWidth = docEl.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      if (clientWidth > 1921) {
        docEl.style.fontSize = 80 * (clientWidth / 1920) + 'px';
      } else if (clientWidth >= 1440) {
        docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px';
      } else if (clientWidth >= 1024) {
        docEl.style.fontSize = 90 * (clientWidth / 1640) + 'px';
      } else if (clientWidth >= 750) {
        docEl.style.fontSize = 115 * (clientWidth / 1360) + 'px';
      } else {
        docEl.style.fontSize = 130 * (clientWidth / 750) + 'px';
      }
    };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, remCalc, false);
  remCalc();
})(document, window);