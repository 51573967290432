/**
 * WOW 初始化
 * data-wow-duration: 更改动画持续时间
 * data-wow-delay: 动画开始前的延迟
 * data-wow-offset: 开始动画的距离（与浏览器底部有关）
 * data-wow-iteration: 动画重复的次数
 */
new WOW().init();

function initVideoPopupEvent() {
    let videoPopup = document.querySelector('.video-popup');
    let videoNode = document.querySelector('.video-popup .video-node');
    let open = function (url) {
        videoNode.src = url;
        videoNode.play();
        videoPopup.classList.add('show');
    };

    let close = function () {
        videoNode.src = '';
        videoPopup.classList.remove('show');
    };

    return {
        open,
        close
    };
}

var initVideo = initVideoPopupEvent();

$('.js-open-video').click(function () {
    var url = $(this).attr('data-url');
    initVideo.open(url);
});

$('.js-video-close').click(function () {
    initVideo.close();
});

$('.btn-search-close-icon').click(function () {
    $('.search-wrapper').removeClass('show');
});

$('.js-return-top').click(function () {
    $('html,body').animate(
        {
            scrollTop: 0
        },
        500
    );
});
